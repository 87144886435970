<template>
	<div v-if="loadkeyboardCustomization" class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		 <loading :active.sync="isLoading"
		         :is-full-page="true"/>
		<!-- <Load v-if="isLoading"></Load> -->
		
		<main v-if="!isLoading">
			<div class="display" :class="{ 'error': artworkError }">
				{{ displayValue ? displayValue : "" }}
			</div>
			<div class="keyboard">
				<button v-for="n in 10" :key="n"
				        class="button-input" :class="'button-' + (n-1)"
				        @click="addNumberToDisplay(n-1)">
					{{n-1}}
				</button>
				<button class="button-erase" @click="deleteNumberFromDisplay()">
					<i class="mm-icon mm-icon-backspace"/>
				</button>
				<button class="button-ok" @click="goToArtwork()">
					OK
				</button>
			</div>
		</main>
		
		<footer v-if="!isLoading">
			<button class="button-white" @click="goBack()">
				<i class="mm-icon mm-icon-arrow-back big"/>
			</button>
			<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
				<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon" :src="customization.recognitionIconUrl" alt="">
				<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import {setFullScreen} from "@/store/helpers";
import idb from "@/api/base/idb";

// import Load from '../components/Load.vue';


export default {
	name: "Keyboard",
	
	components: {
		// Load,
		Loading
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			displayValue: this.$i18n.t('keyboard_code_label'),
			artworkError: false,
			mainButtonsCustomizationEnabled: false,
			artworkIDB: null,
			keyboardCustomizationIidb: null,
			loadkeyboardCustomization: false,
			keyboardCustomization: null,
			// keyboardCustomization: {
			// 	bgColor: "#000000",
			// 	recognitionColor: '#F06140',
			// 	mainButtonsColor: '#72c4d0',
			// 	mainButtonsTextColor: '#ffffff',
			// 	mainButtonsCustomizationEnabled: false,
			// 	artworkBackgroundColor: null,
			// 	artworkTextColor: '#ffffff',
			// 	artworkBackgroundImageUrl: null,
			// 	artworkCustomizationEnabled: false,
			// 	keysColor: '#39393a',
			// 	keysActiveColor: '#e37d25',
			// 	textColor: '#ffffff',
			// 	eraseColor: '#8F8F8F',
			// 	eraseIconColor: '#ffffff',
			// 	okColor: '#72C4D0',
			// 	okTextColor: '#ffffff',
			// 	codeTextColor: '#ffffff',
			// 	active: true,
			// },
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			artworks: state => state.artwork.artworks,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('keyboard_view');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			if((!this.isLoading && this.museum && this.isNetworkOnline) ?? this.keyboardCustomizationIidb) {
				return {

					'--code-font-size': this.displayValue === this.$i18n.t('keyboard_code_label') ? '16px' : '60px',
					'--bg-color': this.keyboardCustomization ? this.keyboardCustomization.bgColor : this.keyboardCustomizationIidb.bgColor,
					'--code-text-color': this.keyboardCustomization ? this.keyboardCustomization.codeTextColor : this.keyboardCustomizationIidb.codeTextColor,
					'--keys-color': this.keyboardCustomization ? this.keyboardCustomization.keysColor : this.keyboardCustomizationIidb.keysColor,
					'--keys-active-color': this.keyboardCustomization ? this.keyboardCustomization.keysActiveColor : this.keyboardCustomizationIidb.keysActiveColor,
					'--text-color': this.keyboardCustomization ? this.keyboardCustomization.textColor : this.keyboardCustomizationIidb.textColor,
					'--erase-color': this.keyboardCustomization ? this.keyboardCustomization.eraseColor : this.keyboardCustomizationIidb.eraseColor,
					'--erase-icon-color': this.keyboardCustomization ? this.keyboardCustomization.eraseIconColor : this.keyboardCustomizationIidb.eraseIconColor,
					'--ok-color': this.keyboardCustomization ? this.keyboardCustomization.okColor : this.keyboardCustomizationIidb.okColor,
					'--ok-text-color': this.keyboardCustomization ? this.keyboardCustomization.okTextColor : this.keyboardCustomizationIidb.okTextColor,
					'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : this.keyboardCustomizationIidb.recognitionColor,
				};
			} else {
				return {};
			}
		}
	},
	
	created() {
		debug.log("[Keyboard] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'Keyboard', params: { idMuseum: this.encrypted } });
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			if (!this.keyboardCustomizationIidb) {
				this.loadMuseum(this.idMuseum)
					.then(() => {
						if(this.museum.keyboardCustomization && this.isNetworkOnline) {
							if(this.museum.keyboardCustomization.active) {
								this.keyboardCustomization = this.museum.keyboardCustomization;
							}
						}
					})
					.finally(() => {
						this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
			}else{
				this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			}			
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
		this.loadArtworkIDB();
		this.keyboardCustomizationIDB();
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		addNumberToDisplay(number) {
			if(this.displayValue === this.$i18n.t('keyboard_code_label')) {
				this.displayValue = "";
			}
			this.artworkError = false;
			if(this.displayValue.length <= 6) {
				this.displayValue += number.toString();
			}
		},
		
		deleteNumberFromDisplay() {
			if(this.displayValue !== this.$i18n.t('keyboard_code_label')) {
				this.artworkError = false;
				this.displayValue = this.displayValue.slice(0, -1);
				if (this.displayValue === "") {
					this.displayValue = this.$i18n.t('keyboard_code_label');
				}
			}
		},
		
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
		
		goToArtwork() {
			setFullScreen();
			let self = this;
			let artworkIdb = this.artworkIDB;
			let artworkLs = this.museum.artwork;

			const artworkSource = artworkIdb ?? artworkLs;
			let artwork = artworkSource.find(artwork => artwork.code === self.displayValue);

			if(artwork) {
				debug.log("[Keyboard] Artwork " + artwork.idArtwork + " (code: " + this.displayValue + ") was found");
				this.$router.push({ name: 'Artwork', params: { idMuseum: this.encrypted, idArtwork: String(artwork.idArtwork) } });
			} else {
				debug.log("[Keyboard] Artwork with code: " + this.displayValue + " was not found");
				
				this.artworkError = true;
				setTimeout(() => {
					this.artworkError = false;
					this.displayValue = this.$i18n.t('keyboard_code_label');
				}, 1000);
			}
		},
		
		goBack() {
			setFullScreen();
			this.$router.push({ name: 'Options', params: { idMuseum: this.encrypted } });
		},

		async loadArtworkIDB() {
			try {
				const artworkIdb = await idb.getAllFromDB('artworks');
				this.artworkIDB = artworkIdb;
				return artworkIdb;
			} catch (error) {
				console.error("Error al obtener las artwork: ", error);
			}
		},

		async keyboardCustomizationIDB() {
			try {
				const keyboardCustomizationIdb = await idb.getAllFromDB('customizationsPresentation');
				this.keyboardCustomizationIidb = keyboardCustomizationIdb[0];
				this.loadkeyboardCustomization = true;
				this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			} catch (error) {
				console.error("Error al obtener las artwork: ", error);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../theme/colors.scss';

.content {
	background-color: var(--bg-color);
	width: 100%;
	height: 100%;
	
	main {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 100%;
		height: calc(100% - 90px);
		
		.display {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: var(--code-font-size);
			color: var(--code-text-color);
			flex: 1;
			width: 100%;
			
			&.error {
				color: $red;
			}
		}
		
		.keyboard {
			flex: 2;
			display: flex;
			justify-content: space-between;
			align-content: stretch;
			flex-wrap: wrap;
			width: 100%;
			
			button {
				border: none;
				margin-top: 10px;
				color: var(--text-color);
				font-size: 32px;
				width: calc(1/3*100% - (1 - 1/3)*10px);
				
				&:active {
					background-color: var(--keys-active-color) !important;
				}
			}
			
			.button-input {
				background-color: var(--keys-color);
			}
			
			.button-0 {
				margin-bottom: 10px;
				order: 11;
			}
			
			.button-erase {
				margin-bottom: 10px;
				background-color: var(--erase-color);
				order: 10;
				display: flex;
				justify-content: center;
				align-items: center;
				
				i {
					font-size: 35px;
					color: var(--erase-icon-color);
				}
			}
			
			.button-ok {
				margin-bottom: 10px;
				text-transform: uppercase;
				background-color: var(--ok-color);
				color: var(--ok-text-color);
				order: 12;
			}
		}
	}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		justify-content: center;
		position: fixed;
		bottom: 0;
		
		button {
			width: 100%;
			border: none;
			text-transform: uppercase;
			display: flex;
			justify-content: center;
			align-items: center;
			
			&.button-white {
				background-color: white;
				color: black;
			}
			
			&.button-recognition {
				color: white;
				background-color: var(--recognition-color);
				
				.recognition-icon {
					max-width: 60%;
					max-height: 80%;
				}
			}
		}
	}
}
</style>
