import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization } from "@/store/helpers";
import idb from "@/api/base/idb";

const state = {
	/**
	 * Array of the infoSections of the museum
	 * @type {Array}
	 */
	infoSections: [],

	/**
	 * Will be true when there is an error fetching infoSections from the museum
	 * @type {boolean}
	 */
	infoSectionsError: false,

	/**
	 * Object representation of the current infoSection
	 * @type {Object}
	 */
	infoSection: null,

	/**
	 * Will be true when there is an error fetching the current infoSection
	 * @type {boolean}
	 */
	infoSectionError: false,
};

const getters = {

};

const actions = {
	/**
	 * Gets the infoSections of the museum
	 * @param rootState
	 * @param state
	 * @param commit
	 */
	getInfoSections: async ({ rootState, state, commit }) => {
		debug.open("getInfoSections");
		commit(mutationTypes.SET_INFO_SECTIONS_ERROR, false);
		let infoSections = [];
		let infoSectionsIdb;
		let inforSectionLS;

		inforSectionLS = rootState.museum.museum.infoSections;
		infoSectionsIdb = await idb.getAllFromDB('infoSections');
		const sourceInfoSection =  inforSectionLS ?? infoSectionsIdb;
		
		if(sourceInfoSection) {
			for(let index in sourceInfoSection) {
				let infoSection = sourceInfoSection[index];
				infoSection.locale = findLocalization(infoSection);
				infoSections.push(infoSection);
			}

			commit(mutationTypes.SET_INFO_SECTIONS, infoSections);
			debug.log("Found " + infoSections.length + " infoSections");

			if(state.infoSections.length < 1) {
				debug.log("There are no infoSections in this museum");
				commit(mutationTypes.SET_INFO_SECTIONS_ERROR, true);
			}
		} else {
			debug.log("There are no infoSections in this museum");
			commit(mutationTypes.SET_INFO_SECTIONS_ERROR, true);
		}
		debug.close();
	},

	/**
	 * Gets a infoSection from the museum based on the given id
	 * @param state
	 * @param commit
	 * @param {(number|string)} idInfoSection - The id of the infoSection
	 */
	findInfoSection: async ({ commit, rootState }, idInfoSection) => {
		debug.open("findInfoSection");
		commit(mutationTypes.SET_INFO_SECTION_ERROR, false);

		let infoSectionIdb;
		let infoSection;

		try {
			infoSectionIdb = await idb.getFromDB(parseInt(idInfoSection), 'infoSections') || null;
		} catch (error) {
			console.error('Error fetching infoSectionIdb:', error);
		}

		infoSection = infoSectionIdb ?? rootState.museum.museum.infoSections.find(section => section.idInfoSection === parseInt(idInfoSection));
		commit(mutationTypes.SET_INFO_SECTION, infoSection);

		if (!state.infoSection) {
			debug.log("InfoSection " + idInfoSection + " could not be found in this museum");
			commit(mutationTypes.SET_INFO_SECTION_ERROR, true);
		} else {
			debug.log("InfoSection " + idInfoSection + " found");
			infoSection.locale = findLocalization(infoSection);
		}
		debug.close();
	},
};

const mutations = {
	[mutationTypes.SET_INFO_SECTIONS]: (state, value) => state.infoSections = value,
	[mutationTypes.SET_INFO_SECTION]: (state, value) => state.infoSection = value,
	[mutationTypes.SET_INFO_SECTIONS_ERROR]: (state, value) => state.infoSectionsError = value,
	[mutationTypes.SET_INFO_SECTION_ERROR]: (state, value) => state.infoSectionError = value,
};

export default {
	moduleName: 'infoSection',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
