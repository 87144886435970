<template>
	<div id="main" class="content" :style="cssVarColor">
		<vue-headful :title="pageTitle" />

		 <loading :active.sync="isLoading" :is-full-page="true" /> 
		<!-- <Load v-if="isLoading"></Load> -->

		<main v-if="!isLoading && route && askCode()" class="paid-main">
			<div v-if="!isLoading && route" class="container">
				<img class="bg-image" :src="route.mainImageUrl" alt="">
			</div>

			<h1>{{ $t('insert_code') }}</h1>

			<div class="input">
				<label>
					{{ $t('necessary_code') }}
					<input ref="input" type="text" @keyup.enter="enterCode()">
				</label>

				<p v-if="wrongCode" class="error">{{ $t('wrong_code') }}</p>
			</div>

			<!--			(MS-828) https://grupotks.atlassian.net/browse/MS-828 -->
			<!--			<div class="payments" @click="makePayment()">-->
			<!--				<p class="text">{{ $t('buy_with') }}</p>-->
			<!--				<div class="payment-method-container">-->
			<!--					<img class="payment-method" :alt="$t('buy_with')" src="@/assets/payment.png">-->
			<!--				</div>-->
			<!--			</div>-->
		</main>

		<main v-else-if="!isLoading && route && route.locale && steps[index]" v-show="!showMapImage" class="free-main">
			<h1>{{ route.locale.name }}</h1>

			<div v-if="!route.steps.length" class="empty-route">
				{{ $t('no_steps') }}
			</div>

			<div v-else class="steps">
				<carousel ref="carousel" :scrollPerPage="false" centerMode class="carousel" :paginationEnabled="false"
					:perPage="1" :spacePadding="0" :adjustableHeight="true" :spacePaddingMaxOffsetFactor="0"
					:navigate-to="pageToGo" @page-change="index = $event">
					<slide v-for="step in steps" :key="step.idStep" class="step" :style="cssVarColor">
						<div v-if="!step.idArtwork && step.stepType !== 3" class="icon-container">
							<div class="walking-icon" :style="cssVarColor">
								<i class="play mm-icon mm-icon-walk white big" />
							</div>
						</div>
						<div v-show="step.idArtwork" class="container-artwork">
							<div v-show="step.idArtwork && !isLoading" class="artwork" @click="goToArtwork(step.idArtwork)">
								<div class="artwork-container">
									<img v-if="museum.code ==='MPICASSOM'" class="artwork-image" :src="step.artwork ? step.artwork.mainImageUrl : getblobImage(step.artwork.idArtwork)
									 " alt="">
									<img v-if="museum.code !=='MPICASSOM'" class="artwork-image" :src="step.artwork ? step.artwork.mainImageUrl : ''
									 " alt="">
								</div>
								<div v-if="step.artwork && step.artwork.mainAudioUrl" class="play-overlay-icon">
									<i class="play mm-icon mm-icon-play white big" />
								</div>
							</div>
							<div v-if="step.artwork" class="step-map-image-container">
								<p v-if="step.artwork" class="step-map-title">
									{{ step.locale.location }}
								</p>

								<img
									class="step-map-image"
									:src="getImageSource(step.locationImageUrl)"
									alt=""
                  @click="showMapImage = true"
                >
							</div>

						</div>

						<p v-if="!step.artwork && step.stepType !== 3" class="step-info">
							{{ step.locale.instructions }}
						</p>

						<div v-if="!step.artwork && step.stepType === 3" class="step-richText" @click="handleClickPay($event.target)">
							<div style="width: 100%;" v-html="step.locale.instructions" />
							<div>
								<stripe-buy-button v-if="(step.idKeyButton && step.idKeyButton !== '') ||
									(step.publishableKeyButton && step.publishableKeyButton !== '')" :buy-button-id="step.idKeyButton"
									:publishable-key="step.publishableKeyButton" />
							</div>

						</div>
						 <loading :active.sync="isLoading" :is-full-page="true" /> 
						<!-- <Load v-if="isLoading"></Load> -->


					</slide>
				</carousel>
			</div>
		</main>

		<div v-if="!isLoading && route && steps[index]" v-show="showMapImage" class="step-map">
			<h2>{{ steps[index].locale.location }}</h2>

			<pinch-zoom class="step-map-pinch" doubleTap="true" wheel="true" limitZoom="5" disableZoomControl="never"
				backgroundColor="rgba(0,0,0,0)" autoHeigh="true">
				<div class="step-map-location">
					<img class="step-map-location-image" :src="getImageSource(steps[index].locationImageUrl)" alt="">
				</div>
			</pinch-zoom>

			<div class="step-map-image-footer" @click="showMapImage = false">
				<i class="mm-icon mm-icon-close big" />
			</div>
		</div>

		<div v-if="!isLoading && routeError && !askCode()" class="empty-route">
			{{ $t('no_resources') }}
		</div>

		<footer v-if="route && askCode()" class="paid-footer" :style="footerCSSVars">
			<div class="footer-buttons">
				<button class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-close big" />
				</button>
				<button class="button-blue" @click="enterCode()">
					<i class="mm-icon mm-icon-arrow-right white big" />
				</button>
			</div>
		</footer>

		<footer v-else class="free-footer" :style="footerCSSVars">
			<button class="button-white" @click="goBack()">
				<i class="mm-icon mm-icon-arrow-back big" />
			</button>
			<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
				<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon"
					:src="customization.recognitionIconUrl" alt="">
				<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
			</button>
		</footer>
	</div>
</template>

<script>
const { debug } = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import { Carousel, Slide } from 'vue-carousel';
import PinchZoom from 'vue-pinch-zoom';
import { setFullScreen } from "@/store/helpers";
import RouteVisit from "@/models/routeVisit";
import MuseumsAPI from "@/api/MuseumsAPI";
import firebase from "firebase/app";
// import Load from '../../components/Load.vue';
import idb from "../../api/base/idb";


export default {
	name: "Route",

	components: {
		// Load,
		Loading,
		Carousel,
		// eslint-disable-next-line vue/no-unused-components
		Slide,
		PinchZoom
	},

	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idRoute: this.$route.params.idRoute || 0,
			isExhibitionView: false,
			wrongCode: false,
			index: 0,
			showMapImage: false,
			navigate: true,
			pageToGo: 0,
			routeVisit: new RouteVisit(),
			sectionExhibitions: null,
			sectionRoutes: null,
			keyboardCustomization: null,
		};
	},

	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			route: state => state.route.route,
			routeError: state => state.route.routeError,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError,
			visit: state => state.app.visit,
			visitv2: state => state.app.visitv2,
			highlightedRoutes: state => state.route.highlightedRoutes,
		}),

		pageTitle() {
			let locationName = this.$i18n.t('selected_routes');
			if (this.route?.locale?.name) {
				locationName = this.route.locale.name;
			}

			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";

			return locationName + ' | ' + museumName;
		},

		steps() {
			 let { steps } = this.highlightedRoutes.find((element) => element.idRoute === this.route.idRoute)  || {};
			 if (steps) {
				steps = Object.values(steps);
              this.route.steps.forEach((element) => {
				const match = steps.find((elementhigh) => elementhigh.idStep === element.idStep);
			 if (match){
				element.locale.instructions = match.localizations[0].instructions;
				element.locale.languageCode= match.localizations[0].languageCode;
				element.locale.location =match.localizations[0].location;
			 }

			 });

			 }
			
			return this.route.steps;
		},

		cssVarColor() {
			let bgColor = null;
			let textColor = null;
           
			if (!this.isLoading) {
				bgColor = (this.isExhibitionView)
					? ((this.sectionExhibitions?.active && this.sectionExhibitions.lineColor) ? this.sectionExhibitions.lineColor : '#17779b')
					: ((this.sectionRoutes?.active && this.sectionRoutes.lineColor) ? this.sectionRoutes.lineColor : '#d1b490');

				textColor = (this.isExhibitionView)
					? ((this.sectionExhibitions?.active && this.sectionExhibitions.arrowColor) ? this.sectionExhibitions.arrowColor : '#ffffff')
					: ((this.sectionRoutes?.active && this.sectionRoutes.arrowColor) ? this.sectionRoutes.arrowColor : '#ffffff');
			}

			return {
				'--bg-color': bgColor,
				'--text-color': textColor
			};
		},

		footerCSSVars() {
			return {
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		},
	},

	created() {
		this.isExhibitionView = this.$route.name.search('Exhibition') !== -1;
		debug.open((this.isExhibitionView ? "Exhibition" : "Route"));
		debug.log("Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);

		let name = (this.isExhibitionView ? "Exhibition" : "Route");
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: name, params: { idMuseum: this.encrypted, idRoute: this.idRoute } });
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			let self = this;
			this.loadMuseum(this.idMuseum)
			
				.then(async () => {
					await this.findRoute({ idRoute: this.idRoute, isExhibitionView: self.isExhibitionView });
					self.getRoutes({exhibitions: self.isExhibitionView , idRoute: this.idRoute});
					this.setRouteVisit();
					self.getHighlightedRoutes();
					this.getNavigationSections();
					this.sectionExhibitions = this.navigationSections.find((s) => s.sectionType === 'EXHIBITIONS');
					this.sectionRoutes = this.navigationSections.find((s) => s.sectionType === 'ROUTES');
					if (this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}

					debug.close();
					await this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);

					if (this.navigate && this.$refs.carousel && this.$route.query.artwork) {
						this.navigate = false;
						let step = this.steps.find((step) => step.idArtwork === parseInt(this.$route.query.artwork));
						if (step) {
							this.index = parseInt(step.index);
							this.pageToGo = parseInt(step.index);
						}
					}

					if (firebase.app().options.measurementId) {
						debug.log("Logging page_view analytics");
						firebase.analytics().logEvent('page_view', {
							page_location: this.$router.currentRoute.fullPath,
							page_title: this.pageTitle
						});
					} else {
						debug.log("No measurementId found, no analytics sent");
					}
				});
		} catch (e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},

	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('route', ['findRoute']),
		...mapActions('route', ['getRoutes']),
		...mapActions('route', ['getHighlightedRoutes']),
		...mapActions('navigationSection', ['getNavigationSections']),

    getImageSource(locationImageUrl) {
      if (locationImageUrl instanceof Blob){
        return URL.createObjectURL(locationImageUrl);
      }

      return locationImageUrl;
    },

		setFullScreen(to = null) {
			setFullScreen();
			if (to) {
				this.$router.push(to);
			}
		},

		async enterCode() {
			setFullScreen();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			if (this.$refs.input && this.route.hasOwnProperty('code')
				&& this.$refs.input.value === this.route.code) {
				debug.log("[enterCode] Correct code, setting the route " + this.route.idRoute + " as paid");

				let paidRoutes = localStorage.getItem('paid-routes');
				if (paidRoutes) {
					paidRoutes = atob(paidRoutes);
					if (!paidRoutes.includes((this.route.idRoute).toString())) {
						paidRoutes += ', ' + this.route.idRoute;
					}
				} else {
					paidRoutes = this.route.idRoute;
				}

				localStorage.setItem('paid-routes', btoa(paidRoutes));

			} else {
				this.wrongCode = true;
				setTimeout(() => {
					this.wrongCode = false;
					this.$refs.input.textContent = "";
				}, 1000);
			}
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		},

		askCode() {
			if (this.route) {
				if (this.route.hasOwnProperty('routeType') && this.route.routeType === 'PAID') {
					let paidRoutes = localStorage.getItem('paid-routes');
					if (paidRoutes) {
						let hasBeenPaid = (atob(paidRoutes)).includes((this.route.idRoute).toString());
						return !hasBeenPaid;
					}
				} else {
					return false;
				}
			}

			return true;
		},

		createNewRouteVisit() {
			localStorage.removeItem('current-artwork-visit');
			this.routeVisit = new RouteVisit(this.idRoute, new Date(), new Date());
			this.$store.commit('app/' + mutationTypes.ADD_ROUTE_VISIT, this.routeVisit);
		},

		setRouteVisit() {
			debug.open("setRouteVisit");

			let currentRouteVisit = localStorage.getItem('current-route-visit');
			try {
				currentRouteVisit = JSON.parse(currentRouteVisit);
				if (currentRouteVisit.idRoute === this.idRoute) {
					debug.log("Continuing with the visit", currentRouteVisit);
					this.routeVisit.parseJSON(currentRouteVisit);
				} else {
					debug.log("There is a visit ongoing, but it is not for this route, finishing it and creating a new one...");
					this.createNewRouteVisit();
				}
			} catch (e) {
				debug.log("There is no visit ongoing, creating one...");
				this.createNewRouteVisit();
			}

			localStorage.setItem('current-route-visit', JSON.stringify(this.routeVisit));

			debug.close();
		},

		makePayment() {
			alert(this.$t('payments_disabled'));
		},

		goToRecognition() {
			setFullScreen();
			if (this.$router.history?.current?.query?.from) {
				this.$router.push({
					name: 'Recognition',
					params: { idMuseum: this.encrypted },
					query: { from: this.$router.history.current.query.from }
				});
			} else {
				this.$router.push({ name: 'Recognition', params: { idMuseum: this.encrypted } });
			}
		},

		async goToArtwork(idArtwork) {
      if (this.isNetworkOnline){
        MuseumsAPI.registerVisitArtworkV2(this.visitv2.idVisit, idArtwork);
      }
			setFullScreen();
			if (this.$router.history?.current?.query?.from) {
				this.$router.push({
					name: 'Artwork',
					params: { idMuseum: this.encrypted, idArtwork: idArtwork },
					query: {
						from: this.$router.history.current.query.from,
						...(this.$route.query.highlightedRoute && { highlightedRoute: this.$route.query.highlightedRoute })
					}
				});
			} else {
				this.$router.push({ name: 'Artwork', params: { idMuseum: this.encrypted, idArtwork: idArtwork } });
			}
		},
		loadingWindowPay() {
			setTimeout(() => {
				this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			}, 500);
			setTimeout(() => {
				this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			}, 5000);
		},
		handleClickPay(e) {
			if (e.parentElement.href !== null && e.parentElement.href !== undefined){
				this.loadingWindowPay();
			}
		},
		async goBack() {
			setFullScreen();

    

        //Register the visit to this artwork
        debug.open("Registering visit");
        this.$store.commit('app/' + mutationTypes.UPDATE_LAST_ROUTE_VISIT);
        this.$store.commit('app/' + mutationTypes.UPDATE_VISIT_END_DATE, new Date());
        debug.log("Finished visit");

        MuseumsAPI.registerVisit(this.idMuseum, {...this.visit, languageCode: this.visitv2?.languageCode || this.visit.languageCode})
            .then((response) => {
              this.$store.commit('app/' + mutationTypes.UPDATE_ID_VISIT, response.data?.data?.IdVisit, { root: true });
            })
            .catch((e) => {
              if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(e);
              localStorage.setItem('visit', JSON.stringify(this.visit));
            })
            .finally(() => {
              this.$store.commit('app/' + mutationTypes.SET_VISIT, this.visit, { root: true });
              this.$store.commit('app/' + mutationTypes.DELETE_ROUTE_VISIT);
              debug.close();
            });
    


			let name = (this.isExhibitionView ? "ExhibitionList" : "RouteList");
			if (this.$router.history?.current?.query?.from) {
				name = this.$router.history.current.query.from;
			}
			await this.$router.push({
				name,
				params: { idMuseum: this.encrypted },
				query: {
					fromRoute: this.idRoute,
					...(this.$route.query.highlightedRoute && { idRoute: this.$route.query.highlightedRoute })
				}
			});
		},
		async getblobImage(idArtwork){
		let indexDbResult;
		try{
		indexDbResult =  await idb.getFromDB(parseInt(idArtwork),'artworks');
		} 
		catch(e){
			console.error('An error has occurred',e);
		}

		if (indexDbResult){
         return this.getImageSource(indexDbResult.mainImageUrl);
		}
	}
	},
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.container-image-floating {
	position: relative;
}
.text-floating-mainImage {
	position:absolute;
	color: rgb(85, 85, 85); 
	bottom: 0;
	left:0;
	font-size: 10px; 
	width: 100%;
	padding-bottom: 6px;
}
.content {
	width: 100%;
	height: 100%;

	.empty-route {
		background-color: white;
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}

	.paid-main {
		height: 100%;

		.container {
			overflow: hidden;
			position: fixed;
			width: 100%;
			height: 100%;
			z-index: -1;

			.bg-image {
				width: 100%;
				height: 100%;
				filter: blur(20px) brightness(50%);
				transform: scale(1.5);
			}
		}

		h1 {
			text-transform: uppercase;
			border-bottom: 1px solid $gray-lightest;
			background-color: var(--bg-color);
			color: var(--text-color);
			padding: 20px;
			width: 100%;
			position: fixed;
			top: 0;
		}

		.input {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			padding: 0 40px;

			label {
				color: white;
				font-size: 18px;
			}

			input {
				margin-top: 50px;
				margin-bottom: 20px;
				padding: 20px;
			}
		}

		.payments {
			position: fixed;
			bottom: 90px;
			width: 100%;
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;
			padding: 20px;

			.text {
				text-align: center;
				margin-bottom: 20px;
			}

			.payment-method-container {
				width: 100%;
				display: flex;
				justify-content: center;

				.payment-method {
					max-width: 70%;
					height: auto;
				}
			}

			&:active {
				background-color: lightgray;
			}
		}

		.error {
			color: $red;
		}
	}

	.free-main {
		width: 100%;
		height: 100%;
		background-color: white;
		position: relative;

		h1 {
			font-size: 22px;
			font-weight: normal;
			background-color: var(--bg-color);
			color: var(--text-color);
			padding: 20px;
			height: 70px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 100%;
			position: fixed;
			top: 0;
			z-index: 2;
		}

		.empty-route {
			padding: 80px 20px 100px 20px;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-size: 22px;
		}

		.steps {

			width: 100%;
			max-height: 90vh;
			display: block;
			text-align: center;
			background-color: white;
			position: absolute;
			overflow: hidden;
			.carousel>* {
				width: 100%;
				min-height: 76vh;
				max-height: 90vh;

				.step {
					height: 100%;
					display: block;
					justify-content: center;
					align-items: center;
					text-align: center;


					.icon-container {
						width: 200px;
						height: 228px;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: auto;
						margin-right: auto;

						&:not(.richText-container):first-child::after {
							content: "";
							margin: 0 50vw;
							width: 100vw;
							top: 50%;
							height: 5px;
							z-index: -1;
							background-color: var(--bg-color);
							position: absolute;
						}

						.walking-icon {
							width: 50px;
							height: 50px;
							background-color: var(--bg-color);
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 10px;
						}
					}

					.artwork {
						width: 200px;
						height: 100px;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: auto;
						margin-right: auto;

						&:not(.richText-container):first-child::after {
							content: "";
							margin: 0 50vw;
							width: 100vw;
							top: 50%;
							height: 5px;
							z-index: -1;
							background-color: var(--bg-color);
							position: absolute;
						}

						.artwork-image {
							min-width: 11em;
							max-width: 90vw;
							max-height: 11em;
						}

						.play-overlay-icon {
							width: 50px;
							height: 50px;
							background-color: rgba(0, 0, 0, 0.5);
							position: absolute;
							border-radius: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
						}

					}

				}
			}

			.container-artwork {
				width: 100%;
				height: 73vh;
				padding-top: 4em;
			}

			.step-richText {
				width: 100%;
				font-size: 24px;
				max-height: 90vh;
				overflow: hidden;
				display: flex;
			}

			.step-info {
				overflow-y:auto;
				flex: 2;
				padding: 0 40px;
				display: flex;
				justify-content: center;
				width: 100%;
				font-size: 21px;
				max-height: 55vh;
				margin-top: -2.5em;
			}
			.step-info::-webkit-scrollbar {
				display: none;
			}

			.step-info {
			scrollbar-width: none;
			}

			.step-map-title {
				flex: 1;
				padding: 10px 20px;
				font-size: 18px;
			}

			.step-map-image-container {
				width: 100%;
				padding-top: 2rem;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 2rem;
				flex-direction: column;

				.step-map-image {
					max-width: 296px;
					max-height: 296px;
				}
			}
		}
	}

	.step-map {
		position: absolute;
		z-index: 10;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: white;
		display: flex;
		flex-direction: column;

		h2 {
			width: 100%;
			font-weight: normal;
			font-size: 22px;
			padding: 20px 20px;
			border-bottom: 1px solid $gray-lightest;
		}

		.step-map-pinch {
			width: 100%;
			flex: 2;
			display: flex;

			.step-map-location {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				.step-map-location-image {
					width: 100%;
					object-fit: cover;
				}
			}
		}


		.step-map-image-footer {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
		}
	}

	.paid-footer {
		position: fixed;
		bottom: 0;
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;

			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;

				&.button-white {
					background-color: white;
					color: black;
				}

				&.button-blue {
					background-color: var(--main-buttons-color);
					color: var(--main-buttons-text-color);

					.mm-icon {
						color: var(--main-buttons-text-color);
					}
				}
			}
		}
	}

	.free-footer {
		height: 90px;
		width: 100%;
		display: flex;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

		button {
			width: 100%;
			border: none;
			text-transform: uppercase;
			display: flex;
			justify-content: center;
			align-items: center;

			&.button-white {
				background-color: white;
				color: black;
			}

			&.button-recognition {
				color: white;
				background-color: var(--recognition-color);

				.recognition-icon {
					max-width: 60%;
					max-height: 80%;
				}
			}
		}
	}
}
</style>
