function blockReloadWhenOffline() {
    console.log("Ingrese al hook de recarga", navigator.onLine);
    // Para navegadores que soportan beforeunload
    const handleBeforeUnload = (event) => {
      if (!navigator.onLine) {
        console.log('Intento de recarga bloqueado: la aplicación está offline.');
        event.preventDefault();
        event.returnValue = ''; // Necesario para navegadores compatibles.
      }
    };
  
    // Para Safari y navegadores que usan visibilitychange
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && !navigator.onLine) {
        console.log('Recarga bloqueada: el usuario intentó cambiar de pestaña mientras está offline.');
      }
    };
  
    // Agregar eventos
    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);
  }
  
  export default blockReloadWhenOffline;
  