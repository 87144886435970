import {debug} from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization } from "@/store/helpers";
import idb from "@/api/base/idb";

const state = {
	/**
	 * Array of the navigationSections of the museum
	 * @type {Array}
	 */
	navigationSections: [],

	/**
	 * Will be true when there is an error fetching navigationSections from the museum
	 * @type {boolean}
	 */
	navigationSectionsError: false,

	/**
	 * Object representing the sponsors of the museum
	 * @type {Object}
	 */
	sponsor: null,

	/**
	 * Will be true when there is an error fetching the sponsors from the museum
	 * @type {boolean}
	 */
	sponsorError: false,
};

const getters = {

};

const actions = {
	/**
	 * Gets the navigationSections of the museum
	 * @param rootState
	 * @param state
	 * @param commit
	 */
	getNavigationSections:async ({ rootState, state, commit }) => {
		debug.open("getNavigationSections");
		commit(mutationTypes.SET_NAVIGATION_SECTIONS_ERROR, false);
		const museum = rootState.museum.museum;
		const museumLS = localStorage.getItem('current-museum');
		const navigationSectionsIDB = await idb.getAllFromDB('navigationSections');
		const navigationSections = museum.navigationSections;
		const routeIdb = await idb.getAllFromDB('routes');
		const route = rootState.museum.museum.routes;

		const navigationSectionSource = navigationSections ?? navigationSectionsIDB;
		const routeSource = route ?? routeIdb;

		if(navigationSectionSource) {
			let navigationSections = [];

			for(let index in navigationSectionSource) {
				let navigationSection = navigationSectionSource[index];
				debug.open("NavigationSection " + navigationSection.idNavigationSection);

				navigationSection.locale = findLocalization(navigationSection);
                
				navigationSections.push(navigationSection);
				debug.close();
			}
			const arrayExhibitions = navigationSections.find(((element) => element.sectionType === 'EXHIBITIONS'));
			 if(arrayExhibitions && arrayExhibitions.length > 0){
				const indexNavigation = navigationSections.findIndex((element) => element.sectionType ==='EXHIBITIONS');
				const indexRoute= routeSource.findIndex((element) => element.isExhibition === true);
				navigationSections[indexNavigation].mainImageUrl = await fetch(routeSource[indexRoute].mainImageUrl).then(r => r.blob());
			 }
			 
			commit(mutationTypes.SET_NAVIGATION_SECTIONS, navigationSections);
			debug.log("Found " + navigationSections.length + " navigationSections");

			if(state.navigationSections.length < 1) {
				debug.log("There are no navigationSections in this museum");
				commit(mutationTypes.SET_NAVIGATION_SECTIONS_ERROR, true);
			}
			debug.close();
			return navigationSections;
		} else {
			debug.log("There are no navigationSections in this museum");
			commit(mutationTypes.SET_NAVIGATION_SECTIONS_ERROR, true);
		}
		debug.close();
	},

	/**
	 * Gets a sponsor resource given its ID
	 * @param state
	 * @param commit
	 * @param {(number|string)} idResource - The id of the resource
	 */
	findSponsors: ({ state, commit }, idResource) => {
		debug.open("findSponsors");
		commit(mutationTypes.SET_SPONSOR_ERROR, false);
		for (let navigationSection of state.navigationSections) {
			if(navigationSection.sectionType === 'SPONSORS') {

				let resource = navigationSection.resources.find(resource => +resource.idResource === +idResource);
				commit(mutationTypes.SET_SPONSOR, resource);

				if (!state.sponsor) {
					debug.log("Sponsor " + idResource + " could not be found");
					commit(mutationTypes.SET_SPONSOR_ERROR, true);
				} else {
					debug.log("Sponsor " + idResource + " found");
					state.sponsor.locale = findLocalization(state.sponsor);
				}
			}
		}
		debug.close();
	},
};

const mutations = {
	[mutationTypes.SET_NAVIGATION_SECTIONS]: (state, value) => state.navigationSections = value,
	[mutationTypes.SET_NAVIGATION_SECTIONS_ERROR]: (state, value) => state.navigationSectionsError = value,
	[mutationTypes.SET_SPONSOR]: (state, value) => state.sponsor = value,
	[mutationTypes.SET_SPONSOR_ERROR]: (state, value) => state.sponsorError = value,
};

export default {
	moduleName: 'navigationSection',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
